import React, { useState, useCallback, useMemo } from 'react';
import { InnerMediaProps } from './types';
import { Figure, MediaSize as MediaSizeComponent, LazyImg } from './styles';
import { generateSrcSet, generateMediaSizes } from './utils';
import { MEDIA_SIZES_ARRAY } from './constants';
import { useDevicePixelRatio } from '../../hooks/useDevicePixelRatio';

export const MediaInner = ({
  src,
  alt,
  ratio,
  width,
  height,
  onLoad,
  isInView,
  quality,
  imageType = 'PRESENTATION',
  forwardRef,
  alwaysLoad,
  dataTestId,
  imageParams
}: InnerMediaProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const currentDpr = useDevicePixelRatio();

  const handleImageLoad = useCallback(
    (_: React.SyntheticEvent<HTMLImageElement>) => {
      setIsLoaded(true);
      onLoad?.();
    },
    [onLoad]
  );

  let paddingTop = 0;
  let mergeProps;

  if (width && height) {
    paddingTop = 100 / (width / height);

    mergeProps = {
      width,
      height
    };
  }

  if (ratio) {
    paddingTop = 100 / ratio;
  }

  const srcSet = useMemo(() => {
    // Get actual DPR, capped at 3
    const actualDpr = Math.min(Math.ceil(currentDpr), 3);

    return generateSrcSet(
      src,
      imageType,
      MEDIA_SIZES_ARRAY,
      quality,
      imageParams,
      actualDpr
    );
  }, [imageType, src, quality, imageParams, currentDpr]);

  const sizes = useMemo(() => generateMediaSizes(), []);

  return (
    <Figure ref={forwardRef} data-testid={dataTestId}>
      {(isInView || alwaysLoad || isLoaded) && (
        <LazyImg
          isLoaded={isLoaded}
          data-testid="full-image"
          src={src}
          srcSet={srcSet}
          sizes={sizes}
          alt={alt}
          onLoad={handleImageLoad}
          decoding="async"
          {...mergeProps}
        />
      )}

      <MediaSizeComponent paddingTop={paddingTop} />
    </Figure>
  );
};
